import { helpers } from "@vuelidate/validators";

const { withMessage } = helpers;

export default {
	validate: withMessage(
		"Wofo emails cannot be used for billing accounts",
		value =>
			["wofo", "medstaff"].every(
				item => !value.toLowerCase().includes(item),
			),
	),
};
