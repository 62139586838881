const tabs = level => [
	{
		path: "overview",
		name: level + "Overview",
		component: () => import(`../components/providers/src/View${level}.vue`),
		props: true,
	},
	{
		path: "edit",
		name: level + "Edit",
		component: () => import("../components/providers/src/editGPD.vue"),
		props: true,
		children: [
			{
				path: ":focus",
				name: level + "EditSpecific",
				component: () =>
					import("../components/providers/src/editGPD.vue"),
			},
		],
	},
	{
		path: "worker-blacklist",
		name: level + "WorkerBlacklist",
		component: () =>
			import("../components/providers/src/WorkerBlacklist.vue"),
		props: true,
	},
	{
		path: "rate-card",
		name: level + "RateCard",
		component: () => import("../components/providers/src/RateCardView.vue"),
		props: true,
	},
	{
		path: "booklet",
		name: level + "Booklet",
		component: () =>
			import("../components/providers/src/BookletEditor.vue"),
		props: true,
	},
	{
		path: "log",
		name: level + "Log",
		component: () => import("../components/providers/src/GPDLog.vue"),
		props: true,
	},
	{
		path: "ProviderWorkers",
		name: level + "ProviderWorkers",
		component: () =>
			import("../components/providers/src/ProviderWorkers.vue"),
		props: true,
	},
	{
		path: "ProviderUsers",
		name: level + "ProviderUsers",
		component: () =>
			import("../components/providers/src/ProviderUsers.vue"),
		props: true,
	},
];

const createProvider = nested => ({
	path: "create-provider",
	name: nested ? "NestedCreateProvider" : "CreateProvider",
	component: () => import("../components/providers/src/CreateProvider.vue"),
	props: true,
});
const createGroup = nested => ({
	path: "create-group",
	name: nested ? "NestedCreateGroup" : "CreateGroup",
	component: () => import("../components/providers/src/CreateGroup.vue"),
	props: true,
});

const routes = [
	{
		path: "providers/",
		name: "GPDList",
		component: () => import("../components/providers/src/GPDList.vue"),
		props: true,
		children: [
			{
				path: "group/:groupId?",
				name: "ProviderGroup",
				props: true,
				redirect: to => `${to.fullPath}/overview`,
				children: [
					...tabs("Group"),
					{
						path: "subs",
						name: "GroupsSubs",
						component: () =>
							import(
								"../components/providers/src/ViewGroupsChildren.vue"
							),
						props: true,
					},
					createProvider(true),
					createGroup(true),
				],
			},
			{
				path: "provider/:providerId?",
				name: "ProviderView",
				props: true,
				redirect: to => `${to.fullPath}/overview`,
				children: [
					...tabs("Provider"),
					{
						path: "departments",
						name: "ProvidersDpeartments",
						component: () =>
							import(
								"../components/providers/src/ViewProvidersDepartments.vue"
							),
						props: true,
					},
				],
			},
			{
				path: "department/:departmentId?",
				name: "ProviderDepartment",
				props: true,
				redirect: to => `${to.fullPath}/overview`,
				children: tabs("Department"),
			},
			createProvider(false),
			createGroup(false),
		],
	},
	{
		path: "billing-accounts/",
		name: "BillingAccounts",
		component: () =>
			import("../components/billingAccounts/src/BillingAccountsList.vue"),
		meta: {
			allowedTeams: ["admin"],
		},
		children: [
			{
				path: ":billingAccountId",
				name: "BillingAccountId",
				redirect: to => `${to.fullPath}/overview`,
				component: () =>
					import("../components/frame/src/BillingAccountsFrame.vue"),
				children: [
					{
						path: "overview",
						name: "BillingAccountOverview",
						component: () =>
							import(
								`../components/billingAccounts/src/BillingAccountOverview.vue`
							),
						props: true,
					},
					{
						path: "edit",
						name: "BillingAccountEdit",
						component: () =>
							import(
								"../components/billingAccounts/src/BillingAccountEdit.vue"
							),
						props: true,
						children: [
							{
								path: ":focus",
								name: "BillingAccountEditSpecific",
								component: () =>
									import(
										"../components/billingAccounts/src/BillingAccountEdit.vue"
									),
							},
						],
					},
					{
						path: "providers",
						name: "BillingAccountGPDList",
						component: () =>
							import(
								`../components/billingAccounts/src/BillingAccountGPDList.vue`
							),
						props: true,
					},
					{
						path: "log",
						name: "BillingAccountLog",
						component: () =>
							import(
								`../components/billingAccounts/src/BillingAccountLog.vue`
							),
						props: true,
					},
				],
			},
		],
	},
];

export default routes;
