import { useActiveUserStore } from "../activeUser";
import { isLoggedIn } from "@/auth";
import { useAppStore } from "@/store/app";

export default async function initActiveUser() {
	const activeUserStore = useActiveUserStore();

	activeUserStore._isStoreReady = false;

	//subscribe to activeUser scope to reload the active user
	const appStore = useAppStore();
	appStore.onDataSuccess(
		"RefreshActiveUser",
		() => {
			activeUserStore.loadUser();
		},
		{ scope: "activeUser" },
	);

	// Check if the user is logged in
	if (!isLoggedIn()) {
		appStore.setLoggedIn(false);
	} else {
		appStore.setLoggedIn(true);

		//get user
		await activeUserStore.loadUser();
	}
}
