import { createApp, ref } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import router from "./router";
import axios from "axios";
import { isTokenAboutToExpire, refreshToken, getToken, logout } from "./auth";
import "./registerServiceWorker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap";
import { useAppStore } from "@/store/app";
import { useActiveUserStore } from "./store/activeUser";

//------------------------ AXIOS ------------------------
axios.defaults.baseURL = process.env.VUE_APP_BACKEND_URL_LOCAL;
axios.interceptors.response.use(
	response => {
		//non-get requests are logged in app store to provide global processing state
		if (response.config.method != "get") {
			const appStore = useAppStore();
			appStore.requestComplete(response.config.url);
		}

		const store = useAppStore();
		if (!response.config.preventHooks)
			store.dataSucceeded(response, response.config.scope);
		return response;
	},
	error => {
		if (error.config.method != "get") {
			const appStore = useAppStore();
			appStore.requestComplete(error.config.url);
		}

		if (
			error.response &&
			[401].includes(error.response.status) &&
			!error.config.preventLogout
		) {
			const store = useAppStore();
			console.error("Authentication error:", error);
			if (store.isLoggedIn) logout();
		}
		if (error.response && [403].includes(error.response.status)) {
			const activeUserStore = useActiveUserStore();
			if (activeUserStore.permissions.team.name == "admin")
				alert("Permission denied.");
			else
				activeUserStore.router.push({
					name: "UnauthorisedAccess",
				});
		}
		return Promise.reject(error);
	},
);
axios.interceptors.request.use(
	async config => {
		if (config.method != "get") {
			const appStore = useAppStore();
			appStore.requestStarted(config.url);
		}

		//refresh token if required
		if (
			!config.url.includes("refresh-token") &&
			isTokenAboutToExpire(60 * 60) //if expiring within next hour
		)
			await refreshToken();

		//apply token
		const token = getToken();
		if (token) {
			config.headers["Authorization"] = `Bearer ${token}`;
		}
		return config;
	},
	error => {
		if (error.config.method != "get") {
			const appStore = useAppStore();
			appStore.requestComplete(error.request.url);
		}

		return Promise.reject(error);
	},
);

const app = createApp(App);
const pinia = createPinia();
const suppressXHRLogs = ref(true);

app.provide("suppressXHRLogs", suppressXHRLogs);

pinia.use(({ store }) => {
	store.router = router;
});

app.use(pinia);
app.use(router);

//global processing state to disable submit buttons
Object.defineProperty(app.config.globalProperties, "$isProcessing", {
	get() {
		const appStore = useAppStore();
		return appStore.isAxiosProcessing;
	},
});

app.mount("#app");
