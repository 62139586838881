const routes = [
	{
		path: "",
		name: "Home",
		component: () => import("../components/AppHome.vue"),
	},
	{
		path: "communications",
		name: "Communications",
		component: () => import("../components/comms/src/Communications.vue"),
	},
	{
		path: "crm",
		name: "CRM",
		component: () => import("../components/comms/src/CRM.vue"),
	},
	{
		path: "analytics",
		name: "Analytics",
		component: () => import("../components/analytics/src/Analytics.vue"),
	},
	{
		path: "nps",
		name: "NPS",
		component: () => import("../components/analytics/src/NPS.vue"),
	},
	{
		path: "Dashboard",
		name: "Dashboard",
		component: () => import("../components/analytics/src/Dashboard.vue"),
	},
	{
		path: "invoices",
		name: "ViewInvoices",
		component: () => import("../components/payments/src/ViewInvoices.vue"),
	},
	{
		path: "xero_auth",
		name: "XeroAutoRedirect",
		component: () => import("../components/miscViews/XeroAuth.vue"),
		meta: {
			allowedTeams: [
				{
					team: "*",
					with: ["xero_connection"],
				},
			],
		},
	},
	{
		path: "prompt",
		name: "PromptStart",
		redirect: { name: "PromptGetDetails" },
	},
	{
		path: "prompt/get-details",
		name: "PromptGetDetails",
		component: () => import("../components/miscViews/PromptGetDetails.vue"),
	},
	{
		path: "prompt/install",
		name: "PromptInstall",
		component: () => import("../components/miscViews/PromptInstall.vue"),
	},
	{
		path: "prompt/notifications",
		name: "PromptNotifications",
		component: () =>
			import("../components/miscViews/PromptNotifications.vue"),
	},
	{
		path: "unauthorised",
		name: "UnauthorisedAccess",
		component: () =>
			import("../components/miscViews/UnauthorisedAccess.vue"),
	},
	{
		path: "/files/:fileId",
		name: "FileViewer",
		component: () => import("../components/miscViews/FileViewer.vue"),
	},
];

export default routes;
