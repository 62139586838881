const routes = [
	{
		path: "/update",
		name: "UpdateSplashScreen",
		component: () =>
			import("../components/miscViews/UpdateSplashScreen.vue"),
	},
	{
		path: "/offline",
		name: "OfflineSplashScreen",
		component: () =>
			import("../components/miscViews/OfflineSplashScreen.vue"),
	},
	{
		path: ":pathMatch(.*)*",
		name: "404",
		component: () => import("../components/miscViews/PageNotFound.vue"),
	},
];

export default routes;
