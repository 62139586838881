import { defineStore } from "pinia";

export const useDataStore = defineStore("dataStore", {
	state: () => ({
		_onDataReady: {},
		_avoidCache: [],
		data: {},
	}),
	actions: {
		setData(key, data, avoidCache = true) {
			//store it
			this.data = {
				...this.data,
				[key]: data,
			};

			//set to avoid cache if required
			if (avoidCache && !this._avoidCache.includes(key))
				this._avoidCache.push(key);

			//call data ready callbacks
			this._dataIsReady(key);

			//cache
			this.cacheLocal();
		},
		appendData(key, data) {
			if (!this.data?.[key]) {
				this.setData(key, data);
				return;
			}
			this.data[key] =
				this.data[key] instanceof Array
					? [...this.data[key], ...data]
					: {
							...this.data[key],
							...data,
						};
			this.cacheLocal();
		},
		clearData(key) {
			if (!this.data[key]) return;

			//delete
			delete this.data[key];

			//remove keys
			this._avoidCache = this._avoidCache.filter(k => k != key);

			this.cacheLocal();
		},
		reset() {
			this._onDataReady = {};
			for (let key in this.data) this.clearData(key);
		},
		cacheLocal() {
			const keysToSave = Object.keys(this.data).filter(
				k => !this._avoidCache.includes(k),
			);
			const dataToSave = keysToSave.reduce(
				(all, key) => ({
					...all,
					[key]: this.data[key],
				}),
				{},
			);

			localStorage.setItem("data", JSON.stringify(dataToSave));
		},
		clearLocal() {
			localStorage.removeItem("data");
		},
		//run callback once data in key exists
		addOnDataReady(key, callback) {
			//if is ready call callback
			if (this.data?.[key]) return callback(this.data[key]);

			//else store
			this._onDataReady[key] = [
				...(this._onDataReady?.[key] ?? []),
				callback,
			];
		},
		_dataIsReady(key) {
			const callbacks = this._onDataReady?.[key] ?? [];
			for (let callback of callbacks) callback(this.data[key]);
			delete this._onDataReady[key];
		},
	},
});
