const Domains = {
	NZ: {
		emailDomain: "wofo.co.nz",
		appDomain: "app.wofo.co.nz",
		appUrl: "https://app.wofo.co.nz",
		siteDomain: "wofo.co.nz",
		siteUrl: "https://wofo.co.nz",
		phoneNum: "034777970",
	},
	AU: {
		emailDomain: "wofo.com.au",
		appDomain: "app.wofo.co.nz",
		appUrl: "https://app.wofo.co.nz",
		siteDomain: "wofo.com.au",
		siteUrl: "https://wofo.com.au",
		phoneNum: "0739169636",
	},
};

export default Domains;
