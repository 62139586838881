const editingRoutes = type => [
	{
		path: "details",
		name: type + "UserProfileDetails",
		component: () =>
			import("../components/userProfile/src/UserProfileDetails.vue"),
		props: true,
		children: [
			{
				path: ":focus",
				name: type + "UserProfileDetailsSpecific",
				component: () =>
					import(
						"../components/userProfile/src/UserProfileDetails.vue"
					),
			},
		],
	},
	{
		path: "profile",
		name: type + "UserProfile",
		component: () =>
			import("../components/userProfile/src/UserProfile.vue"),
		props: true,
		children: [
			{
				path: ":focus",
				name: type + "UserProfileSpecific",
				component: () =>
					import("../components/userProfile/src/UserProfile.vue"),
			},
		],
	},
	{
		path: "settings",
		name: type + "UserProfileSettings",
		component: () =>
			import("../components/userProfile/src/UserProfileSettings.vue"),
		props: true,
		children: [
			{
				path: ":focus",
				name: type + "UserProfileSettingsSpecific",
				component: () =>
					import(
						"../components/userProfile/src/UserProfileSettings.vue"
					),
			},
		],
	},
	//if fails to find match forward to /details/ROUTE (will always work and route will often be in there)
	{
		path: ":pathMatch(.*)*",
		name: type + "UserChild404",
		beforeEnter: (to, NULL, next) => {
			const requestedPath =
				to.params.pathMatch[to.params.pathMatch.length - 1] || "";
			const detailsRoute = `../user/details/${requestedPath}`;
			next(detailsRoute);
		},
	},
];

const routes = [
	{
		path: "user/",
		name: "User",
		redirect: "/user/profile/",
		children: editingRoutes("My"),
		meta: {
			allowedTeams: ["*"],
		},
	},
	{
		path: "users/",
		name: "UserList",
		component: () => import("../components/users/src/UserList.vue"),
		children: [
			{
				path: ":userId",
				name: "UsersEdit",
				redirect: to => `/users/${to.params.userId}/overview`,
				component: () => import("../components/frame/src/EditUser.vue"),
				children: [
					...editingRoutes(""),
					{
						path: "overview",
						name: "UserOverview",
						component: () =>
							import("../components/users/src/UserOverview.vue"),
						props: true,
					},
					{
						path: "signup-progress",
						name: "UserSignupProgress",
						component: () =>
							import(
								"../components/users/src/UserSignupProgress.vue"
							),
						props: true,
					},
					{
						path: "signup-review",
						name: "UserSignupReview",
						component: () =>
							import(
								"../components/users/src/UserSignupReview.vue"
							),
						props: true,
					},
					{
						path: "log",
						name: "UserLog",
						component: () =>
							import("../components/users/src/UserLog.vue"),
						props: true,
					},
					{
						path: "invoices",
						name: "UserInvoices",
						component: () =>
							import(
								"../components/payments/src/ViewInvoices.vue"
							),
						props: true,
					},
					{
						path: "permissions",
						name: "UserPermissions",
						component: () =>
							import(
								"../components/users/src/UserPermissions.vue"
							),
						props: true,
					},
					{
						path: "providers",
						name: "UserProviders",
						component: () =>
							import("../components/users/src/UserProviders.vue"),
						props: true,
					},
					{
						path: "notifications",
						name: "UserNotifications",
						component: () =>
							import(
								"../components/userProfile/src/ManageNotifications.vue"
							),
						props: true,
					},
					{
						path: "questionaire/",
						name: "UserQuestionaire",
						component: () =>
							import(
								"../components/onboarding/src/OnboardingQuestionaire.vue"
							),
						props: true,
						children: [
							{
								path: ":focus",
								name: "UserQuestionaireSpecific",
								component: () =>
									import(
										"../components/onboarding/src/OnboardingQuestionaire.vue"
									),
							},
						],
					},
					//if fails to find match forward to /details/ROUTE (will always work and route will often be in there)
					{
						path: ":pathMatch(.*)*",
						name: "UserChild404",
						beforeEnter: (to, NULL, next) => {
							const requestedPath =
								to.params.pathMatch[
									to.params.pathMatch.length - 1
								] || "";
							const detailsRoute = `details/${requestedPath}`;
							next(detailsRoute);
						},
					},
				],
			},
		],
	},
	{
		path: "reference/:userId",
		name: "UserReference",
		props: true,
		component: () =>
			import("../components/onboarding/src/OnboardingReferenceCheck.vue"),
		children: [
			{
				path: ":focus",
				name: "UserReferenceSpecific",
				component: () =>
					import(
						"../components/onboarding/src/OnboardingReferenceCheck.vue"
					),
			},
		],
	},
];

export default routes;
