import { defineStore } from "pinia";
import { isLoggedIn } from "@/auth";

export const useAppStore = defineStore("appStore", {
	state: () => {
		return {
			dataFailiureCallbacks: [],
			dataSuccessCallbacks: [],
			isLoggedIn: isLoggedIn(),
			getInputUsingModal: null,
			data: {},
			activeRequests: [],
			returnRoutes: [],
		};
	},
	actions: {
		getIfInstalled() {
			return (
				window.matchMedia("(display-mode: standalone)").matches ||
				window.navigator.standalone ||
				document.referrer.includes("android-app://")
			);
		},
		getIfMobileDevice() {
			var userAgent =
				navigator.userAgent || navigator.vendor || window.opera;

			// Check for common mobile device user agents
			if (
				/android/i.test(userAgent) ||
				(/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream)
			) {
				return true;
			}

			return false;
		},
		requestStarted(identifier) {
			this.activeRequests.push(identifier);
		},
		requestComplete(identifier) {
			const index = this.activeRequests.indexOf(identifier);
			this.activeRequests.splice(index, 1);
		},
		setData(key, value) {
			//only to be used for creating app functionality
			this.data[key] = value;
		},
		clearData(key) {
			delete this.data[key];
		},
		setLoggedIn(newVal) {
			this.isLoggedIn = newVal;

			//clear data callbacks if not logged in
			if (!newVal) {
				this.dataFailiureCallbacks = [];
				this.dataSuccessCallbacks = [];
			}
		},
		onDataFailiure(id, func, config) {
			const alreadyInStore = this.dataFailiureCallbacks.some(
				i => i.id == id,
			);
			if (alreadyInStore) return;
			this.dataFailiureCallbacks.push({
				id,
				func,
				config: {
					scope: config?.scope ?? undefined,
					methods: config?.methods ?? ["POST", "PUT", "DELETE"],
				},
			});
		},
		dataFailed(response, scope = []) {
			for (let item of this.dataFailiureCallbacks) {
				//either not scoped or scope matches
				const scopeMatch =
					!item?.config?.scope ||
					(item?.config?.scope && scope.includes(item.config.scope));
				//must match request method
				const methodMatch = item.config.methods.includes(
					response.config.method,
				);

				if (scopeMatch && methodMatch) item.func(response);
			}
		},
		onDataSuccess(id, func, config) {
			const alreadyInStore = this.dataSuccessCallbacks.some(
				i => i.id == id,
			);
			if (alreadyInStore) return;
			this.dataSuccessCallbacks.push({
				id,
				func,
				config: {
					scope: config?.scope ?? undefined,
					methods: (config?.methods ?? ["POST", "PUT", "DELETE"]).map(
						i => i.toLowerCase(),
					),
				},
			});
		},
		dataSucceeded(response, scope = []) {
			for (let item of this.dataSuccessCallbacks) {
				//either not scoped or scope matches
				const scopeMatch =
					!item?.config?.scope ||
					(item?.config?.scope && scope.includes(item.config.scope));
				//must match request method
				const methodMatch = item.config.methods.includes(
					response.config.method,
				);

				if (scopeMatch && methodMatch) item.func(response);
			}
		},
		removeDataHook(id) {
			//if multiple call self with each
			if (id instanceof Array) return id.map(this.remove);

			//remove from both
			this.dataFailiureCallbacks = this.dataFailiureCallbacks.filter(
				i => i.id != id,
			);
			this.dataSuccessCallbacks = this.dataSuccessCallbacks.filter(
				i => i.id != id,
			);
		},
		setInputsModalFunction(func) {
			this.getInputUsingModal = func;
		},
	},
	getters: {
		isAxiosProcessing: state => !!state.activeRequests.length,
	},
});
