import { defineStore } from "pinia";
import initActiveUser from "./helpers/initActiveUser";
import { isLoggedIn, getActiveUser } from "@/auth";
import populateDataStoreToEditUser from "./helpers/populateDataStoreToEditUser";
import { decodeToken } from "@/utils/jwt-utils";
import * as menuItems from "../components/frame/config/menuItems";
import getLoginRedirect from "../components/frame/config/getLoginRedirect";
import { logout } from "../auth";
import Domains from "../config/domains";

const NOPERMISSIONS = {
	team: {},
	resources: [],
	userId: "",
	userStatus: "",
};

export const useActiveUserStore = defineStore("activeUser", {
	state: () => {
		setTimeout(() => initActiveUser(), 0);

		//initialise permissions from local token
		let permissions = NOPERMISSIONS;
		let decodedToken = null;
		const token = localStorage.getItem("token");
		if (token) {
			const decoded = decodeToken(token);
			permissions = decoded.permissions;
			decodedToken = decoded;
		}

		return {
			user: {},
			_onStoreReady: [],
			_isStoreReady: false,
			decodedToken,
			permissions,
		};
	},
	actions: {
		loadPermissions() {
			const token = localStorage.getItem("token");
			if (token) {
				const decoded = decodeToken(token);
				this.permissions = decoded.permissions;
				this.decodedToken = decoded; // Store the entire decoded token
			}
		},
		async loadUser() {
			if (!isLoggedIn()) return;

			//check online
			if (!navigator.onLine) {
				this.router.replace({ name: "OfflineSplashScreen" });
				return;
			}

			let fetchedUser = {};
			try {
				this.loadPermissions();

				//ensure in onboarding if required
				if (this.permissions.team.name == "worker") {
					const inOnboarding =
						window.location.href.includes("onboarding");
					const isPending =
						this.permissions.userStatus.includes("pending");
					if (isPending && !inOnboarding)
						this.router.push("/onboarding");
					else if (!isPending && inOnboarding)
						this.router.push("/bookings");
				}

				fetchedUser = await getActiveUser();
				Object.assign(this.user, fetchedUser);

				//login One Signal
				window.OneSignalDeferred = window.OneSignalDeferred || [];
				window.OneSignalDeferred.push(OneSignal =>
					OneSignal.login(fetchedUser.user_id),
				);
			} catch (err) {
				console.error("Error loading user or decoding token:", err);
				throw "Failed to load active user or decode token";
			}

			const cacheData = true;
			const inUsers = window.location.href.includes("users");

			if (!inUsers && fetchedUser && fetchedUser.user_id) {
				await populateDataStoreToEditUser(
					fetchedUser.user_id,
					cacheData,
				).catch(() => logout());
			}

			this.runStoreReady();
		},
		clearUser() {
			this.user = {};
			this.decodedToken = null;
			this.permissions = NOPERMISSIONS;
			this.clearLocal();

			this._isStoreReady = false;

			//logout One Signal (disabled to allow the user to continue to receive notifications once logged out)
			// window.OneSignalDeferred = window.OneSignalDeferred || [];
			// window.OneSignalDeferred.push(OneSignal => OneSignal.logout());
		},
		cacheLocal() {
			localStorage.setItem("activeUser", JSON.stringify(this.user));
		},
		clearLocal() {
			localStorage.removeItem("activeUser");
		},
		addOnStoreReady(func) {
			if (this._isStoreReady) func();
			else this._onStoreReady.push(func);
		},
		runStoreReady() {
			//mark ready
			this._isStoreReady = true;

			// Call onStoreReady functions, if any
			if (Array.isArray(this._onStoreReady) && this._onStoreReady.length)
				for (let func of this._onStoreReady) func();

			//reset
			this._onStoreReady = [];
		},
		/**
		 * Method to check if the user belongs to one of the allowed teams.
		 *
		 * @param {string|string[]|Object[]} allowedTeams - The allowed teams, which can be:
		 *   - A string: the name of a single allowed team.
		 *   - An array of strings: multiple allowed team names.
		 *   - An array of objects: each object can have a `team` property and an optional `if` function for further checks.
		 *     - `team`: the name of the team.
		 *     - `userIs`: the status of the user.
		 *     - `with`: array of additional required resources.
		 *     - `if(): boolean`: a function that returns `true` if the user has permissions, `false` otherwise.
		 *
		 * @returns {function} Middleware function to be used in an Express route.
		 */
		checkPermissions(...props) {
			let allowedTeams = [],
				context = {};
			if (props[0].allowedTeams) {
				allowedTeams =
					props[0].allowedTeams instanceof Array
						? props[0].allowedTeams
						: [props[0].allowedTeams];
				context = props[0].context;
			} else allowedTeams = [...props];

			for (let team of allowedTeams) {
				//test team name
				const teamName = team?.team ?? team;
				if (teamName != this.permissions.team.name && teamName != "*")
					continue;

				//if user status
				if (team?.userIs && this.permissions.userStatus != team.userIs)
					continue;

				//if with check additional resources
				if (team?.with) {
					const hasAll = team.with.every(permission =>
						this.permissions.resources.find(
							p => p.name == permission,
						),
					);
					if (!hasAll) continue;
				}

				//if callback test it
				const callback = team?.if;
				if (callback) {
					const result = callback(context);
					if (!result) continue;
				}

				return true;
			}

			return false;
		},
	},
	getters: {
		full_name: state =>
			state.user?.user_id
				? `${state.user.first_name} ${state.user.last_name}`
				: "",
		initials: state =>
			state.user?.user_id
				? `${state.user.first_name[0]}${state.user.last_name[0]}`
				: "",
		userId: state => state.user?.user_id && state.user.user_id,
		user_id: state => state.user?.user_id && state.user.user_id,
		mainMenu: state =>
			menuItems
				.main(state.permissions.team?.name)
				.filter(({ allowedTeams }) =>
					state.checkPermissions(...allowedTeams),
				),
		accountMenu: state =>
			menuItems
				.account(state.permissions.team?.name)
				.filter(({ allowedTeams }) =>
					state.checkPermissions(...allowedTeams),
				),
		loginRedirect: state => {
			return getLoginRedirect(state.permissions);
		},
		domains: state => Domains[state?.user?.country || "NZ"],
	},
});
