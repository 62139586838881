<template>
	<div style="height: 100vh; height: 100svh; width: 100vw; width: 100svw">
		<!-- 
			Fixed positioning to fix overflow bug in chrome
			Where some nested control panels overflow caused whitespace at bottom of page outside the DOM that cannot be removed
		-->
		<router-view />
		<get-inputs-modal ref="getInputsModal" />
	</div>
</template>

<script>
import { defineComponent } from "vue";
import GetInputsModal from "./components/wofoUtils/modals/GetInputsModal.vue";
import { useActiveUserStore } from "./store/activeUser";
import { useAppStore } from "./store/app";

export default defineComponent({
	components: { GetInputsModal },
	name: "App",
	setup() {
		//active user store must always be loaded in order for data store to be populated
		const activeUserStore = useActiveUserStore();

		return { activeUserStore };
	},
	mounted() {
		const appStore = useAppStore();
		const showModal = this.$refs.getInputsModal.show;
		appStore.setInputsModalFunction(showModal);
	},
	beforeCreate() {
		//OneSignal
		window.OneSignalDeferred = window.OneSignalDeferred || [];
		window.OneSignalDeferred.push(OneSignal =>
			OneSignal.Notifications.addEventListener(
				"permissionChange",
				permission => {
					//do something with permission change
					permission;
				},
			),
		);
	},
	beforeMount() {
		const appStore = useAppStore();
		window.addEventListener("beforeinstallprompt", e => {
			e.preventDefault();
			appStore.setData("installEvent", e);
		});
	},
});
</script>

<style>
@import "../public/css/customStyles.css";
@import url("../src/config/colors.css");
@import "./bootstrap-overrides.css";
</style>
