/* eslint-disable */

//https://www.npmjs.com/package/register-service-worker
import { register } from "register-service-worker";
import { useAppStore } from "./store/app";

//Registers the service worker when the application is running in production mode.
if (process.env.NODE_ENV === "production") {
	register(`/service-worker.js`, {
		registrationOptions: { scope: "./" },
		//Callback for when the service worker is ready.
		ready(registration) {
			console.log(
				"App is being served from cache by a service worker.\nFor more details, visit https://goo.gl/AFskqB",
			);
		},

		//Callback for when the service worker has been registered.
		registered(registration) {
			console.log("Service worker has been registered.");
		},

		//Callback for when the content has been cached for offline use.
		cached(registration) {
			console.log("Content has been cached for offline use.");
		},

		//Callback for when new content is found and being downloaded.
		updatefound(registration) {
			console.log("New content is downloading.");
		},

		//Callback for when the new content has been downloaded and is available.
		updated(registration) {
			console.log("New content is available; please refresh.");
			const appStore = useAppStore();
			registration.addEventListener;
			appStore.setData("ServiceWorkerRegistration", registration);
			appStore.router.push({ name: "UpdateSplashScreen" });
		},

		//Callback for when the application is offline. Note: this is only called when a request is made through the service worker that results in an error
		offline() {
			console.log(
				"No internet connection found. App is running in offline mode.",
			);
			const appStore = useAppStore();
			appStore.router.push({ name: "OfflineSplashScreen" });
		},

		/**
		 * Callback for handling errors during service worker registration.
		 * @param {Error} error - The error object.
		 */
		error(error) {
			console.error("Error during service worker registration:", error);
		},
	});
}
