/**
 * Imports
 */
import axios from "axios";
import { decodeToken } from "@/utils/jwt-utils";
import { useActiveUserStore } from "@/store/activeUser";
import { useAppStore } from "@/store/app";
import { useDataStore } from "@/store/data";
import { allowedWithoutAuth } from "./router";

/**
 * Logs in a user.
 * @param {object} credentials - User login credentials.
 * @param {string} redirectUrl - URL to redirect to after login.
 * @returns {Promise<{ok: boolean, message?: string}>} - A Promise that resolves with login status.
 */
export async function login(credentials, redirectUrl) {
	const activeUserStore = useActiveUserStore();
	try {
		const response = await axios.post("api/v1/users/login", credentials);
		const { token } = response.data;

		localStorage.setItem("token", token);

		const appStore = useAppStore();
		appStore.setLoggedIn(true);

		activeUserStore.loadPermissions();

		if (activeUserStore.router) {
			//worker prompts
			if (
				activeUserStore.checkPermissions({
					team: "worker",
					userIs: "active",
				})
			) {
				activeUserStore.router.replace({
					name: "PromptStart",
					query: {
						redirect:
							redirectUrl?.length > 1 ? redirectUrl : undefined,
					},
				});
			} else if (redirectUrl?.length > 1)
				activeUserStore.router.push(redirectUrl);
			else activeUserStore.router.push(activeUserStore.loginRedirect);
		}

		await activeUserStore.loadUser();

		return {
			ok: true,
		};
	} catch (error) {
		console.error("Login Error:", error);
		const message = error?.response?.data?.message ?? error?.data?.message;
		return {
			ok: false,
			message,
		};
	}
}

export async function sendPasswordResetEmail(email) {
	try {
		await axios.post("api/v1/users/send-password-reset", { email });

		return {
			ok: true,
		};
	} catch (error) {
		console.error(error);
		const message = error?.response?.data?.message ?? error?.response?.data;
		return {
			ok: false,
			message,
		};
	}
}

/**
 * Checks if a user is logged in.
 * @returns {boolean} - True if the user is logged in, false otherwise.
 */
export const isLoggedIn = () =>
	Boolean(localStorage.getItem("token")) && !isTokenExpired();

/**
 * Logs out a user.
 * @returns {Promise<void>} - A Promise that resolves after logging out the user.
 */
export const logout = async redirect => {
	if (redirect instanceof Event) redirect = null;

	const activeUserStore = useActiveUserStore();
	const dataStore = useDataStore();
	const appStore = useAppStore();

	appStore.setLoggedIn(false);

	dataStore.reset();

	if (
		activeUserStore.router &&
		//don't redirect if in login
		activeUserStore.router.currentRoute.name != "UserLogin" &&
		//don't redirect if current route is allowed without auth
		!allowedWithoutAuth.includes(activeUserStore.router.currentRoute.name)
	) {
		const redirectTo = redirect ?? appStore.router.currentRoute.fullPath;
		activeUserStore.router.push({
			name: "UserLogin",
			query: { redirect: redirectTo },
		});
	}

	const token = localStorage.getItem("token");
	if (token) {
		try {
			// Make the POST request to the logout route
			await axios.post(
				"api/v1/auth/logout",
				{ token },
				{ preventHooks: true },
			);
		} catch (error) {
			// Handle network or request-related errors
			console.error("Logout Error end of logout:", error);
		}
	}

	activeUserStore.clearUser();
	localStorage.removeItem("token");
};

/**
 * Gets the JWT token from local storage.
 * @returns {string|null} - The JWT token or null if not found.
 */
export const getToken = () => localStorage.getItem("token");

/**
 * Gets the active user data.
 * @returns {Promise<object|null>} - A Promise that resolves with the active user data or null if an error occurs.
 */
export const getActiveUser = async () => {
	try {
		const response = await axios.get("api/v1/auth/active-user");
		return response.data;
	} catch (error) {
		console.error("Error getting active user:", error);
		return null;
	}
};

/**
 * Checks if the JWT token is expired.
 * @returns {boolean} - True if the token is expired, false otherwise.
 */
export const isTokenExpired = () => {
	const token = localStorage.getItem("token");
	if (!token) return true;

	const decodedToken = decodeToken(token);
	const currentTime = Date.now() / 1000;
	return decodedToken.exp < currentTime;
};

/**
 * Refreshes the JWT token by calling the backend endpoint designed for token refresh.
 * Updates the local storage and Axios default headers with the new token.
 * @returns {Promise<boolean>} - A Promise that resolves with true if the token was successfully refreshed, false otherwise.
 */
export const refreshToken = async () => {
	try {
		const currentToken = getToken();
		if (!currentToken) return false;

		const response = await axios.get("api/v1/auth/refresh-token", {
			headers: { Authorization: `Bearer ${currentToken}` },
			preventHooks: true,
		});

		const { token } = response.data;
		if (token) {
			localStorage.setItem("token", token);

			// Optionally, reset any timers or perform additional state updates as needed
			setTokenRefreshTimer();
			return true;
		} else {
			logout();
			return false;
		}
	} catch (error) {
		console.error("Error refreshing token:", error);
		logout();
		return false;
	}
};

/**
 * Sets a timer to refresh the JWT token before it expires.
 */
export const setTokenRefreshTimer = () => {
	const token = getToken();
	if (token) {
		const decodedToken = decodeToken(token);
		const currentTime = Date.now() / 1000;
		const timeUntilExpiry = (decodedToken.exp - currentTime) * 1000;

		setTimeout(() => {
			refreshToken();
		}, timeUntilExpiry - 60000); // Refresh 1 minute before expiry
	}
};

/**
 * Checks if the JWT token is about to expire.
 * @param {number} thresholdInSeconds - The threshold in seconds to consider the token as about to expire.
 * @returns {boolean} - True if the token is about to expire within the given threshold, false otherwise.
 */
export const isTokenAboutToExpire = (thresholdInSeconds = 300) => {
	// 300 seconds = 5 minutes
	const token = localStorage.getItem("token");
	if (!token) return false; // No token means not about to expire

	try {
		const decodedToken = decodeToken(token);
		const currentTime = Date.now() / 1000; // Convert to seconds
		const timeLeft = decodedToken.exp - currentTime;
		return timeLeft < thresholdInSeconds;
	} catch (error) {
		console.error("Error decoding token:", error);
		return false; // If there's an error decoding the token, consider it not about to expire for safety
	}
};
