import { useAppStore } from "../store/app";

export default async function () {
	//check if google src loaded
	const appStore = useAppStore();
	const googleAddressScript = appStore.data.googleAddressScript;
	if (!googleAddressScript) {
		//else load
		const script = document.createElement("script");
		script.async = true;
		script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.VUE_APP_GOOGLE_API_KEY}&callback=initGoogleMapsApi&libraries=places`;
		document.head.appendChild(script);

		//initialize
		const prom = new Promise(resolve => {
			window.initGoogleMapsApi = () => {
				resolve();
			};
		});
		appStore.setData("googleAddressScript", prom);
		await prom;
	} else {
		await googleAddressScript;
	}
}
