import { DateTime } from "luxon";
import { useActiveUserStore } from "../store/activeUser";

export const FormatText = {
	capitalize: {
		eachWord(textString, requiresSplit = false) {
			if (!textString) return "";
			let string = textString;

			if (requiresSplit) string = FormatText.split.onChars(string);

			return string
				.split(" ")
				.map(
					chunk =>
						chunk[0].toUpperCase() + chunk.slice(1).toLowerCase(),
				)
				.join(" ");
		},
		fromCamelCase(textString) {
			return FormatText.capitalize.eachWord(
				FormatText.split.onCamelCase(textString),
			);
		},
	},
	split: {
		onChars(textString, splitChars = ["_", "."]) {
			let newString = textString;
			for (let char of splitChars)
				newString = newString.split(char).join(" ");
			return newString;
		},
		onCamelCase(textString) {
			return textString.split(/(?=[A-Z])/).join(" ");
		},
	},
	money: (value, hide0Decimal = true, useCommas = true) => {
		value = +value;
		if (isNaN(value)) throw new Error("Value must be a number");

		// Ensure 2 decimal points
		let formattedValue = value.toFixed(2);

		// Remove decimal points if 0
		if (hide0Decimal) formattedValue = formattedValue.replace(/\.00$/, "");

		// Add commas
		if (useCommas)
			formattedValue = formattedValue
				.toString()
				.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

		let finalValue = `$${formattedValue}`;

		//Negative sign before symbol
		const isNegative = formattedValue < 0;
		if (isNegative) {
			finalValue = "-" + finalValue.replace("-", "");
		}

		// Return with $ symbol
		return finalValue;
	},
	hourlyRate(value) {
		return `${this.money(value)}/hr`;
	},
	url: {
		mapFromCoords: (latitude, longitude) =>
			`https://www.google.com/maps/search/?api=1&query=${latitude}%2C${longitude}`,
		xeroContact: contactId =>
			`https://go.xero.com/app/contacts/contact/${contactId}`,
	},
};

export const FormatDateTime = {
	getActiveUserZone: () => {
		const activeUserStore = useActiveUserStore();
		return activeUserStore.user.timezone;
	},
	prepTimeComponent: time =>
		time
			.split(":")
			.map(n => {
				n = n + "";
				if (n.length == 1) return "0" + n;
				return n;
			})
			.join(":"),
	toLuxon(input) {
		//allows input of anything and output as Luxon object
		if (!input) return DateTime.fromMillis(0);
		if (input instanceof DateTime) return input;
		if (!isNaN(input)) {
			const isMillis =
				Math.abs(Date.now() - input) <
				Math.abs(Date.now() - input * 1000);
			if (isMillis) return DateTime.fromMillis(+input);
			else return DateTime.fromSeconds(+input);
		}
		if (input.includes("T"))
			return DateTime.fromISO(input, { zone: "utc" });
		return DateTime.fromSQL(input, { zone: "utc" });
	},
	parse(input, timezone) {
		return timezone
			? this.toLuxon(input).setZone(timezone)
			: this.toLuxon(input);
	},
	now(timezone) {
		return timezone ? DateTime.now().setZone(timezone) : DateTime.now();
	},
	asToday(value, timezone, fallback) {
		//if input is today show as "Today"
		const dt = this.parse(value, timezone);
		const now = this.now(timezone);
		if (dt.hasSame(now, "day")) return "Today";

		//else use fallback
		return this[fallback](value, timezone);
	},
	asDay(value, timezone, fallback) {
		//if input is next 6 days show as day
		const dt = this.parse(value, timezone);
		const now = this.now(timezone);
		if (dt.hasSame(now, "day")) return "Today";

		const next6 = now.plus({ days: 6 });
		if (dt < next6) return dt.weekdayLong;

		//else use fallback
		return this[fallback](value, timezone);
	},
	shortDateTime(value, timezone) {
		const dt = this.parse(value, timezone);
		return dt.toFormat("dd/MM/yyyy HH:mm");
	},
	localShortDateTime(value) {
		const dt = this.parse(value, this.getActiveUserZone());
		return dt.toFormat("dd/MM/yyyy HH:mm");
	},
	shortDateFullTime(value, timezone) {
		const dt = this.parse(value, timezone);
		return dt.toFormat("dd/MM/yyyy HH:mm:ss");
	},
	localShortDateFullTime(value) {
		const dt = this.parse(value, this.getActiveUserZone());
		return dt.toFormat("dd/MM/yyyy HH:mm:ss");
	},
	shortDate(value, timezone) {
		const dt = this.parse(value, timezone);
		return dt.toFormat("dd/MM/yyyy");
	},
	localShortDate(value) {
		const dt = this.parse(value, this.getActiveUserZone());
		return dt.toFormat("dd/MM/yyyy");
	},
	longDate(value, timezone) {
		const dt = this.parse(value, timezone);
		return `${dt.day} ${dt.monthLong} ${dt.year}`;
	},
	localLongDate(value) {
		const dt = this.parse(value, this.getActiveUserZone());
		return `${dt.day} ${dt.monthLong} ${dt.year}`;
	},
	time(value, timezone) {
		const dt = this.parse(value, timezone);
		return dt.toFormat("HH:mm");
	},
	localTime(value) {
		const dt = this.parse(value, this.getActiveUserZone());
		return dt.toFormat("HH:mm");
	},
	fullTime(value, timezone) {
		const dt = this.parse(value, timezone);
		return dt.toFormat("HH:mm:ss");
	},
	localFullTime(value) {
		const dt = this.parse(value, this.getActiveUserZone());
		return dt.toFormat("HH:mm:ss");
	},
};

const Format = {
	text: FormatText,
	datetime: FormatDateTime,
};

export default Format;
