import dobValidator from "./dobValidator";
import emailNotTaken from "./emailNotTaken";
import password from "./password";
import phone from "./phone";
import NZ_ACCOUNT_NUM from "./nzBankAccount";
import ABN from "./abn";
import emailNotWofo from "./emailNotWofo";

export default {
	dobValidator,
	emailNotTaken,
	password,
	phone,
	NZ_ACCOUNT_NUM,
	ABN,
	emailNotWofo,
};
