import { helpers } from "@vuelidate/validators";

const { withMessage } = helpers;

/**
 * Validates whether the given date of birth indicates an age between 16 and 120 years.
 *
 * @param {string | Date} value - The date of birth to validate. Can be a string or a Date object.
 * @returns {boolean} - Returns true if the age is between 16 and 120, false otherwise.
 */
function dobValidator(value) {
	/**
	 * Calculates the age based on the given date of birth.
	 *
	 * @param {string | Date} dob - The date of birth. Can be a string or a Date object.
	 * @returns {number} - The calculated age.
	 */
	const calculateAge = dob => {
		const today = new Date();
		const birthDate = new Date(dob);
		let age = today.getFullYear() - birthDate.getFullYear();
		const m = today.getMonth() - birthDate.getMonth();
		if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
			age--;
		}
		return age;
	};

	const age = calculateAge(value);
	return age >= 16 && age <= 120;
}

export default {
	...withMessage("You must be between 16 and 120 years old.", dobValidator),
};
