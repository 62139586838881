import { helpers, or, email, not } from "@vuelidate/validators";
import axios from "axios";

const { withAsync, withMessage } = helpers;

/**
 * A Vue component validator that checks if an email is already in use by making an asynchronous request.
 *
 * Utilizes Vuelidate's `withAsync` and `withMessage` helpers for asynchronous validation and custom error messages.
 */
export default {
	...withMessage(
		"Email is already in use",
		or(
			not(email),
			withAsync(
				/**
				 * Asynchronously checks if the provided email is already in use.
				 *
				 * @param {string} email - The email address to validate.
				 * @returns {Promise<boolean>} - A promise that resolves to `true` if the email is not in use, `false` otherwise.
				 */
				async email => {
					try {
						const response = await axios.get(
							"api/v1/users/check-email",
							{
								params: { email },
							},
						);
						return response.data.available !== false;
					} catch (error) {
						return false;
					}
				},
			),
		),
	),
};
