const routes = [
	{
		path: "/onboarding/",
		name: "RootOnboarding",
		redirect: () => {
			return { name: "OnboardingWelcome" }; //temp straight to welcome
		},
		component: () => import("../components/frame/src/OnboardingFrame.vue"),
		meta: {
			allowedTeams: [
				{
					team: "worker",
					userIs: "pending",
				},
				{
					team: "worker",
					userIs: "pending approval",
				},
				{
					team: "worker",
					userIs: "pending declined",
				},
			],
		},
		children: [
			{
				path: "welcome",
				name: "OnboardingWelcome",
				component: () =>
					import(
						"../components/onboarding/src/OnboardingWelcome.vue"
					),
			},
			{
				path: "details",
				name: "OnboardingDetails",
				component: () =>
					import(
						"../components/userProfile/src/UserProfileDetails.vue"
					),
				props: true,
				children: [
					{
						path: ":focus",
						name: "OnboardingDetailsSpecific",
						component: () =>
							import(
								"../components/userProfile/src/UserProfileDetails.vue"
							),
					},
				],
			},
			{
				path: "contracting",
				name: "OnboardingContracting",
				component: () =>
					import(
						"../components/onboarding/src/OnboardingContracting.vue"
					),
			},
			{
				path: "documents",
				name: "OnboardingDocuments",
				component: () =>
					import(
						"../components/onboarding/src/OnboardingDocuments.vue"
					),
			},
			{
				path: "profile/",
				name: "OnboardingProfile",
				component: () =>
					import("../components/userProfile/src/UserProfile.vue"),
				props: true,
				children: [
					{
						path: ":focus",
						name: "OnboardingProfileSpecific",
						component: () =>
							import(
								"../components/userProfile/src/UserProfile.vue"
							),
					},
				],
			},
			{
				path: "questionaire/",
				name: "OnboardingQuestionaire",
				component: () =>
					import(
						"../components/onboarding/src/OnboardingQuestionaire.vue"
					),
				props: true,
				children: [
					{
						path: ":focus",
						name: "OnboardingQuestionaireSpecific",
						component: () =>
							import(
								"../components/onboarding/src/OnboardingQuestionaire.vue"
							),
					},
				],
			},
			{
				path: "complete",
				name: "OnboardingComplete",
				component: () =>
					import(
						"../components/onboarding/src/OnboardingComplete.vue"
					),
			},
		],
	},
];

export default routes;
