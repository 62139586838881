const routes = [
	{
		path: "payments/",
		name: "Payments",
		component: () => import("../components/frame/src/PaymentFrame.vue"),
		redirect: { name: "PaymentsInvoices" },
		children: [
			{
				path: "invoices",
				name: "PaymentsInvoices",
				component: () =>
					import("../components/payments/src/PaymentsInvoices.vue"),
				props: true,
			},
			{
				path: "disbursements",
				name: "PaymentsDisbursements",
				component: () =>
					import(
						"../components/payments/src/PaymentsDisbursements.vue"
					),
				props: true,
			},
			{
				path: "remittances",
				name: "PaymentsRemittances",
				component: () =>
					import(
						"../components/payments/src/PaymentsRemittances.vue"
					),
				props: true,
			},
			{
				path: "payruns",
				name: "PaymentsPayruns",
				component: () =>
					import("../components/payments/src/PaymentsPayruns.vue"),
				props: true,
				children: [
					{
						path: ":payrunId",
						name: "PayrunManage",
						component: () =>
							import(
								"../components/payments/src/PayrunManage.vue"
							),
						props: true,
					},
				],
			},
		],
	},
];

export default routes;
