<template>
	<div>
		<div class="mb-1">
			<div class="form-floating">
				<input
					ref="autocomplete"
					type="text"
					:class="`form-control ${
						v$.autocompleteText.$dirty &&
						(v$.autocompleteText.$error ? 'is-invalid' : 'is-valid')
					}`"
					:id="id"
					v-model="inputText"
					@focus="focusHandler"
					@blur="blurHandler"
					@keyup.delete="reset"
					:placeholder="placeholder || ''"
					:disabled="disabled"
				/>
				<label :for="id" class="form-label">{{
					placeholder ||
					`${FormatText.capitalize.eachWord(type)} Address`
				}}</label>
			</div>
			<input-feedback
				:componentsErrors="v$.autocompleteText.$errors"
				:label="`${FormatText.capitalize.eachWord(type)} Address`"
			/>
		</div>
		<div
			class="mb-1"
			v-for="[key, details] in this.hasDetailed &&
			Object.entries(detailedAddressElements)"
			:key="key"
		>
			<div class="form-floating" v-if="this.expanded">
				<input
					type="text"
					:class="`form-control ${
						v$.fullAddress?.[key]?.$dirty &&
						(v$.fullAddress?.[key]?.$error
							? 'is-invalid'
							: 'is-valid')
					}`"
					:id="`${id}-${key}_input`"
					v-model="fullAddress[key]"
					@focus="focusHandler"
					@blur="blurHandler"
					placeholder=""
					:disabled="disabled"
				/>
				<label :for="id" class="form-label">{{ details.label }}</label>
			</div>
			<input-feedback
				:componentsErrors="v$.fullAddress?.[key]?.$errors ?? []"
				:label="details.label"
			/>
		</div>
		<div
			class="form-check form-switch mb-1 w-100 small form-control-sm w-100 d-flex justify-content-end gap-1"
			style="margin-top: -0.5em"
			v-if="!notExpanded"
		>
			<input
				class="form-check-input"
				type="checkbox"
				role="switch"
				:id="`${id}-expander`"
				v-model="fixedExpanded"
			/>
			<label class="form-check-label" :for="`${id}-expander`"
				>Expand full address</label
			>
		</div>
	</div>
</template>

<script>
/* global google */
import { defineComponent } from "vue";
import InputFeedback from "./InputFeedback.vue";
import { useAppStore } from "../../store/app";
import { FormatText } from "@/helpers/formats";
import useVuelidate from "@vuelidate/core";
import { required, requiredIf, helpers } from "@vuelidate/validators";
const { withMessage } = helpers;

import loadGoogleMaps from "../../utils/loadGoogleMaps";

//places billing: https://developers.google.com/maps/documentation/places/web-service/usage-and-billing#location-placedetails
const BASIC_DATA_FIELDS = [
	"address_components",
	"adr_address",
	"alt_id",
	"formatted_address",
	"geometry",
	"icon",
	"id",
	"name",
	"business_status",
	"photo",
	"place_id",
	"scope",
	"type",
	"url",
	"utc_offset_minutes",
	"vicinity",
];
const detailedAddressElements = {
	city: {
		placeValue: "locality",
		label: "City",
	},
	sub_city: {
		placeValue: "sublocality",
		label: "Sub City",
	},
	country: {
		placeValue: "country",
		label: "Country",
	},
	postal_code: {
		placeValue: "postal_code",
		label: "Postal Code",
	},
};
const addressConfig = {
	...detailedAddressElements,
	street_name: {
		placeValue: "route",
		label: "Street Name",
	},
	street_number: {
		placeValue: "street_number",
		label: "Street Number",
	},
	latitude: {
		label: "Latitude",
	},
	longitude: {
		label: "Longitude",
	},
};

export default defineComponent({
	name: "address-input",
	props: {
		countryRestriction: [Array, String],
		type: {
			type: String,
			default: "primary",
		},
		searchTypes: {
			//https://developers.google.com/maps/documentation/javascript/place-autocomplete#constrain-place-types
			//setting this will restirct the input to only allow set types
			type: Array,
			default: () => ["address"],
		},
		modelValue: {
			type: Object,
			default: () => ({}),
		},
		validationScope: [String, Boolean],
		notExpanded: Boolean,
		placeholder: String,
		disabled: Boolean,
	},
	expose: ["fixedExpanded"],
	components: { InputFeedback },
	setup(props) {
		return {
			v$: useVuelidate({
				$scope: props.validationScope || `address_${props.type}`,
				$autoDirty: props.validationScope !== false,
			}),
			timeout: null,
		};
	},
	data(props) {
		return {
			autocomplete: null,
			autocompleteText:
				props.modelValue.street_number && props.modelValue.street_name
					? `${props.modelValue.street_number} ${props.modelValue.street_name}`
					: "",
			geolocation: {
				geocoder: null,
				loc: null,
				position: null,
			},
			detailedAddressElements,
			focusWithin: false,
			fixedExpanded: false,
			FormatText,
		};
	},
	emits: ["update:modelValue", "change"],
	computed: {
		id() {
			const random = Math.floor(Math.random() * Date.now());
			return `address_${this.type}_${random}`;
		},
		hasDetailed() {
			return !!this.fullAddress.country;
		},
		fullAddress: {
			get() {
				return this.modelValue;
			},
			set(value) {
				this.autocompleteText =
					value.street_name && value.street_number
						? `${value.street_number} ${value.street_name}`
						: value.street_number || value.street_name || "";
				this.$emit("update:modelValue", value);
				this.$emit("change", value);
			},
		},
		expanded() {
			return (
				!this.notExpanded && (this.fixedExpanded || this.focusWithin)
			);
		},
		formatted() {
			if (!this.fullAddress.longitude) return "";

			return [
				this.fullAddress.street_number,
				" ",
				this.fullAddress.street_name,
				", ",
				this.fullAddress.sub_city != this.fullAddress.city &&
					this.fullAddress.sub_city,
				", ",
				this.fullAddress.city,
				", ",
				this.fullAddress.postal_code,
				" ",
				this.fullAddress.country,
			]
				.map((item, index, arr) => {
					if (!item && !(index % 2)) arr[index + 1] = false;
					return item;
				})
				.filter(i => i)
				.join("");
		},
		inputText: {
			get() {
				return this.focusWithin
					? this.autocompleteText
					: this.formatted;
			},
			set(value) {
				this.autocompleteText = value.split(",")[0];
			},
		},
	},
	validations() {
		return {
			autocompleteText: {
				required: requiredIf(!this.fullAddress.city),
				//requirelong and lat as well
				hasLongLat: withMessage(
					"Please select location from the list.",
					() =>
						this.fullAddress.latitude && this.fullAddress.longitude,
				),
			},
			fullAddress: {
				// city: { required: requiredIf(this.fullAddress.postal_code) },
				// sub_city: {},
				// country: { required: requiredIf(this.fullAddress.postal_code) },
				postal_code: {
					required: requiredIf(this.fullAddress.postal_code),
				},
				street_name: {
					required: requiredIf(this.fullAddress.postal_code),
				},
				street_number: {
					required: requiredIf(this.fullAddress.postal_code),
				},
				city: { required },
				sub_city: {},
				country: { required },
				// postal_code: { required },
				// street_name: { required },
				// street_number: { required },
			},
		};
	},
	watch: {
		autocompleteText(nv, ov) {
			if (nv == ov) return;
			if (
				this.fullAddress.street_name &&
				this.fullAddress.street_number
			) {
				const [streetNum, ...streetName] = nv.split(",")[0].split(" ");
				this.fullAddress.street_name = streetName.join(" ");
				this.fullAddress.street_number = streetNum;
			}
		},
		countryRestriction(nv) {
			//ensure array
			let countries = nv;
			if (typeof nv == "string") countries = countries.split(",");

			//pass through to google thingy
			const appStore = useAppStore();
			const googleAddressScript = appStore.data.googleAddressScript;
			googleAddressScript.then(() => {
				this.autocomplete.setComponentRestrictions({
					country: countries,
				});
			});
		},
		modelValue(nv, ov) {
			if (
				nv.street_name == ov.street_name &&
				nv.street_number == ov.street_number
			)
				return;
			this.autocompleteText =
				nv.street_name && nv.street_number
					? `${nv.street_number} ${nv.street_name}`
					: nv.street_number || nv.street_name || "";
		},
	},
	async beforeMount() {
		await loadGoogleMaps();

		//initialize
		const options = {
			types: this.searchTypes,
			componentRestrictions: {
				country: this.countryRestriction,
			},
		};
		this.autocomplete = new google.maps.places.Autocomplete(
			document.getElementById(this.id),
			options,
		);
		this.autocomplete.setFields(BASIC_DATA_FIELDS);
		this.autocomplete.addListener("place_changed", this.onPlaceChanged);
	},
	methods: {
		reset(event) {
			//only reset if no input value
			if (event.target.value) return;

			//clear value
			this.autocompleteText = "";
			this.fullAddress = {};
		},
		onPlaceChanged() {
			this.fixedExpanded = true;

			//get full details
			let place = this.autocomplete.getPlace();

			if (!place.geometry) return;

			if (place.address_components !== undefined) {
				this.autocompleteText = place.name;

				this.configureFullAddress(place);
			}
		},
		configureFullAddress(place) {
			const newAddress = Object.entries(addressConfig).reduce(
				(full, [key, details]) => {
					//long and lat separate
					const lngLatConfig = {
						latitude: "lat",
						longitude: "lng",
					};
					if (Object.keys(lngLatConfig).includes(key))
						full[key] =
							place.geometry.location[lngLatConfig[key]]();
					//else get from place address components
					else
						full[key] = place.address_components.find(i =>
							i.types.includes(details.placeValue),
						)?.long_name;

					return full;
				},
				{},
			);
			// Object.assign(this.fullAddress, newAddress);
			this.fullAddress = newAddress;
		},
		blurHandler() {
			this.timeout = setTimeout(() => {
				this.focusWithin = false;
			}, 50);
		},
		focusHandler() {
			clearTimeout(this.timeout);
			this.focusWithin = true;
		},
	},
});
</script>
