import { helpers } from "@vuelidate/validators";
import { parsePhoneNumberFromString } from "libphonenumber-js/min";

const { withMessage } = helpers;

/**
 * A collection of validators for form inputs.
 *
 * Utilizes Vuelidate's `withMessage` helper for custom error messages.
 */
export default {
	/**
	 * Validator to check if a string is a valid phone number.
	 * Uses `libphonenumber-js` for parsing and validating the phone number.
	 *
	 * @type {Validator}
	 */
	validate: withMessage(
		"Please enter a valid phone number",
		/**
		 * Validates a phone number string.
		 *
		 * @param {string} value - The phone number to validate.
		 * @returns {boolean} - Returns true if the phone number is valid, false otherwise.
		 */
		value => {
			const num = parsePhoneNumberFromString(value, {
				extract: false,
			});
			return num ? num.isValid() : false;
		},
	),
};
