import { helpers } from "@vuelidate/validators";

const { withMessage, regex } = helpers;

/**
 * Validators for checking if a string contains specific types of characters.
 * Utilizes Vuelidate's `withMessage` and `regex` helpers.
 */
export default {
	/**
	 * Validator to check if a string contains at least one uppercase character.
	 *
	 * @type {Validator}
	 */
	containsUppercase: withMessage(
		"Must contain at least one uppercase character",
		regex(/[A-Z]/),
	),

	/**
	 * Validator to check if a string contains at least one lowercase character.
	 *
	 * @type {Validator}
	 */
	containsLowercase: withMessage(
		"Must contain at least one lowercase character",
		regex(/[a-z]/),
	),

	/**
	 * Validator to check if a string contains at least one numeral.
	 *
	 * @type {Validator}
	 */
	containsNum: withMessage(
		"Must contain at least one number",
		regex(/[0-9]/),
	),

	/**
	 * Validator to check if a string contains at least one special character.
	 *
	 * @type {Validator}
	 */
	containsSpecial: withMessage(
		"Must contain at least one special character",
		regex(/[#?!@$.%^&*-]/),
	),
};
