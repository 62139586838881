import { helpers } from "@vuelidate/validators";
import bankValidator from "./NZ-Bank-Account-Validator";

const { withMessage } = helpers;

export default withMessage(
	"Account Number is not valid",
	value =>
		bankValidator.validate(value) && !isNaN(+value.replaceAll("-", "")),
);
