import { jwtDecode } from "jwt-decode";
import { logout } from "../auth";

export const decodeToken = token => {
	try {
		return jwtDecode(token);
	} catch (error) {
		console.error("Error decoding token:", error);
		logout();
		return null;
	}
};
