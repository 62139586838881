<template>
	<div
		:v-if="componentsErrors.length"
		:class="`invalid-feedback ${componentsErrors.length && 'd-block'}`"
	>
		{{
			componentsErrors
				.map(error =>
					error.$message
						.replace("Value", label)
						.replace("The value", label)
						.replace("This field", label),
				)
				.join(", ")
		}}
	</div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
	name: "input-feedback",
	props: {
		componentsErrors: Object,
		label: String,
	},
});
</script>
