<template>
	<div
		class="modal fade"
		ref="modal"
		tabindex="-1"
		aria-labelledby="modal-title"
		aria-hidden="true"
	>
		<div class="modal-dialog">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title" id="modal-title">
						{{ useTitle }}
					</h5>
					<button
						type="button"
						class="btn-close"
						@click="cancelModal"
						aria-label="Close"
					></button>
				</div>
				<div class="modal-body">
					<input-from-json
						v-for="config in useInputs"
						:key="config.key"
						:inputConfig="config"
						v-model="responses[config.key]"
						class="mb-2"
						@valueChanged="
							newVal =>
								config?.change
									? (inputValues = config.change(
											responses,
											newVal,
									  ))
									: () => {}
						"
					/>
				</div>
				<div class="modal-footer">
					<button
						type="button"
						class="btn btn-secondary"
						@click="cancelModal"
					>
						Cancel
					</button>
					<button
						type="button"
						class="btn btn-primary"
						@click="handleSubmit"
					>
						Submit
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { defineComponent, reactive } from "vue";
import InputFromJson from "../InputFromJson.vue";
import { useVuelidate } from "@vuelidate/core";

export default defineComponent({
	components: { InputFromJson },
	name: "get-inputs-modal",
	props: {
		inputs: {
			type: Array,
			default: () => [],
		}, //value = inputs from JSON
		title: {
			type: String,
			default: "",
		},
	},
	setup() {
		const responses = reactive({});
		const v$ = useVuelidate();

		const programaticSetup = reactive({
			title: null,
			inputs: null,
		});

		return {
			responses,
			v$,
			promise: null,
			programaticSetup,
		};
	},
	computed: {
		useInputs() {
			return this.programaticSetup.inputs || this.inputs;
		},
		useTitle() {
			return this.programaticSetup.title || this.title;
		},
	},
	expose: ["show"],
	emit: ["cancel", "submit"],
	methods: {
		show(inputs, title) {
			this.programaticSetup.title = title;
			this.programaticSetup.inputs = inputs;

			for (let input of inputs)
				if (input.value) this.responses[input.key] = input.value;

			this.$refs.modal.classList.add("show");
			this.$refs.modal.style.display = "block";

			const that = this;

			const prom = new Promise((res, rej) => {
				that.promise = {
					res,
					rej,
				};
			});

			return prom;
		},
		hide() {
			this.$refs.modal.classList.remove("show");
			this.$refs.modal.style.display = "none";

			//clear programatic setup
			this.programaticSetup.inputs = null;
			this.programaticSetup.title = null;

			//clear responses
			this.responses = {};
		},
		cancelModal() {
			this.hide();
			this.$emit("cancel");

			this.promise.rej();
		},
		async handleSubmit() {
			const valid = await this.v$.$validate();
			if (!valid) return;

			const response = JSON.parse(JSON.stringify(this.responses));

			this.hide();
			this.$emit("submit", response);

			this.promise.res(response);
		},
	},
});
</script>
