const routes = [
	{
		path: "userlogin",
		name: "UserLogin",
		component: () => import("../components/login/src/UserLogin.vue"),
	},
	{
		path: "userregistration",
		name: "UserRegistration",
		component: () => import("../components/login/src/UserRegistration.vue"),
	},
	{
		path: "logged-in",
		name: "LoggedIn",
		component: () => import("../components/login/src/LoggedIn.vue"),
	},
	{
		path: "verify-email",
		name: "VerifyEmail",
		component: () => import("../components/login/src/VerifyEmail.vue"),
	},
	{
		path: "reset-password",
		name: "ResetPassword",
		component: () => import("../components/login/src/ResetPassword.vue"),
	},
	{
		path: "accept-invite",
		name: "AcceptInvite",
		component: () => import("../components/login/src/AcceptInvite.vue"),
	},
];

export default routes;
